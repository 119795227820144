import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, throwError, tap } from 'rxjs';
import {
  mergeMap,
  filter,
  first,
  timeout,
  catchError,
  concatMap,
  map,
} from 'rxjs/operators';
import {
  CheckInActionGQL,
  CheckOutActionGQL,
  CheckInInput,
  CheckInType,
  CheckOutInput,
  CreateNextComponentVersionActionGQL,
  CreateNextComponentVersionAndRenameActionGQL,
  CreatePreviewsActionGQL,
  CreateProjectActionGQL,
  CreateTaskActionGQL,
  CreateVariantActionGQL,
  CreateNextComponentVersionInput,
  CreateNextComponentVersionRenameInput,
  CreatePreviewsInput,
  CreateProjectInput,
  CreateTaskInput,
  CreateVariantInput,
  DuplicateInstanceInput,
  Location,
  DuplicateInstanceActionGQL,
  CheckoutBlobFileSynchronizedSubscriptionGQL,
  FreezeComponentVersionInput,
  CreateFileInput,
  StartReleaseProcessInput,
  QuickReplaceComponentInput,
  EnumsChecklistTypeEnum,
  StartReleaseProcessActionGQL,
  ExportReviewedComponentsGQL,
  QuickReplaceComponentGQL,
  CheckReleaseProcessStepActionGQL,
  CheckReleaseProcessStepInput,
  FreezeComponentVersionActionGQL,
  UpdateBlobsFromUploadActionGQL,
  CreateNewVersionFromPreviousInput,
  CreateNewComponentVersionFromPreviousActionGQL,
  AddChecklistTemplateGQL,
  CreateDrawingActionGQL,
  CreateFilesActionGQL,
  CreateDrawingInput,
  LatestBlobSubscriptionGQL,
} from '@genetpdm/model/graphql';
import { LoggingService } from '../logging-service/logging.service';
import { SettingsService } from '../settings-service/settings.service';
import { handleGraphQlErrors } from '../utility/graphql-utilities';
import { ComponentInstanceVersion, PreviewType } from '@genetpdm/model';
import { FileSystemAPIService } from '../filesystem-api-service/filesystem-api.service';
import { ProjectService } from '../project-service/project.service';
import { environment } from 'apps/genetpdm-frontend/src/environments';

@Injectable({
  providedIn: 'root',
})
/**
 * Service that defines all actions currently implemented in the pdm
 */
export class ActionsService {
  userLocation = '';

  /**
   *
   * @param settingsService Service for managing the settings
   * @param fileSystemApi Service for communication with the filesystem
   * @param projectService Service for managing the project
   * @param latestBlobSubscription gql subscription which returns the latest blob
   * @param checkInAction gql action for checkin in a component
   * @param checkOutAction gql action for checkin in a component
   * @param createNextVersionAction gql action for creating a next version
   * @param createNewVersionFromPreviousAction gql action for creating a new version from the previous action
   * @param createChecklistTemplateAction gql action for creating a checklist template
   * @param renameAction gql action for renaming
   * @param createVariantAction gql action for creating a variant
   * @param createProjectAction gql action for creating a project
   * @param createTaskAction gql action for creating a task
   * @param createPreviewsAction gql action for creating the previews
   * @param freezeComponentAction gql action for freezing a component
   * @param duplicateInstanceAction gql action for duplicating an instance
   * @param updateFromUploadAction gql action to update a blob based on an upload
   * @param createDrawingAction gql action to create a drawing
   * @param createFilesAction gql action to create files
   * @param checkoutBlobFileSynchronized
   * @param startReleaseProcessAction
   * @param checkReleaseProcessStepAction
   * @param exportReviewedComponentsAction
   * @param quickReplaceComponentsAction
   * @param logger
   */
  constructor(
    private settingsService: SettingsService,
    private fileSystemApi: FileSystemAPIService,
    private projectService: ProjectService,
    private latestBlobSubscription: LatestBlobSubscriptionGQL,
    private checkInAction: CheckInActionGQL,
    private checkOutAction: CheckOutActionGQL,
    private createNextVersionAction: CreateNextComponentVersionActionGQL,
    private createNewVersionFromPreviousAction: CreateNewComponentVersionFromPreviousActionGQL,
    private createChecklistTemplateAction: AddChecklistTemplateGQL,
    private renameAction: CreateNextComponentVersionAndRenameActionGQL,
    private createVariantAction: CreateVariantActionGQL,
    private createProjectAction: CreateProjectActionGQL,
    private createTaskAction: CreateTaskActionGQL,
    private createPreviewsAction: CreatePreviewsActionGQL,
    private freezeComponentAction: FreezeComponentVersionActionGQL,
    private duplicateInstanceAction: DuplicateInstanceActionGQL,
    private updateFromUploadAction: UpdateBlobsFromUploadActionGQL,
    private createDrawingAction: CreateDrawingActionGQL,
    private createFilesAction: CreateFilesActionGQL,
    private startReleaseProcessAction: StartReleaseProcessActionGQL,
    private checkReleaseProcessStepAction: CheckReleaseProcessStepActionGQL,
    private exportReviewedComponentsAction: ExportReviewedComponentsGQL,
    private quickReplaceComponentsAction: QuickReplaceComponentGQL,
    private logger: LoggingService
  ) {
    this.settingsService.getLocation$.subscribe(
      (loc) => (this.userLocation = loc)
    );
  }

  public checkIn$(
    component: ComponentInstanceVersion,
    check_in_type: CheckInType,
    comment: string,
    additionalBlobs: string[]
  ) {
    const input = <CheckInInput>{
      component_id: component.component.id,
      component_version: component.latestVersion.index,
      comment: comment,
      check_in_type: check_in_type,
      additional_blob_routes: additionalBlobs,
    };

    const checkIn$ = this.checkOutSynchronizedWithUserLocation$(component).pipe(
      filter(isSynchronized => isSynchronized),
      mergeMap(() => this.checkInAction.mutate({ input: input })),
      handleGraphQlErrors()
    );

    //No need to wait for sync
    const cancelCheckOut$ = this.checkInAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());

    if (check_in_type == CheckInType.Cancel) {
      return cancelCheckOut$;
    } else {
      return checkIn$;
    }
  }

  public checkOut$(component: ComponentInstanceVersion) {
    const input = <CheckOutInput>{
      component_id: component.component.id,
      component_version: component.latestVersion.index,
      location: this.userLocation,
    };

    return this.checkOutAction
      .mutate({ input: input })
      .pipe(
        concatMap(() => this.checkOutSynchronizedWithUserLocation$(component))
      );
  }

  public createNextComponentVersion$(
    component: ComponentInstanceVersion,
    comment: string
  ) {
    const input = <CreateNextComponentVersionInput>{
      component_id: component.component.id,
      comment: comment,
    };

    return this.createNextVersionAction
      .mutate({ content: input })
      .pipe(handleGraphQlErrors());
  }

  public restoreComponentVersion$(
    component: ComponentInstanceVersion,
    version: number
  ) {
    const input = <CreateNewVersionFromPreviousInput>{
      component_id: component.component.id,
      component_version: version,
    };

    return this.createNewVersionFromPreviousAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public createDrawing$(input: CreateDrawingInput) {
    return this.createDrawingAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public upsertChecklistTemplate$(
    id: uuid,
    name: string,
    type: EnumsChecklistTypeEnum,
    data: any
  ) {
    return this.createChecklistTemplateAction
      .mutate({
        id: id.toString(),
        name: name,
        data: data,
        type: type,
      })
      .pipe(handleGraphQlErrors());
  }

  public createFiles$(input: CreateFileInput) {
    return this.createFilesAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public duplicateInstance$(component: ComponentInstanceVersion) {
    const input = <DuplicateInstanceInput>{
      instance_id: component.instance.id,
      component_id: component.component.id,
    };

    return this.duplicateInstanceAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public renameComponent$(
    componentId: uuid,
    partNumber: string,
    newFilename: string
  ) {
    const input = <CreateNextComponentVersionRenameInput>{
      component_id: componentId,
      part_number: partNumber,
      new_filename: newFilename,
    };

    return this.renameAction
      .mutate({
        content: input,
      })
      .pipe(handleGraphQlErrors());
  }

  public createVariant$(
    variantName: string,
    originalComponentId: uuid,
    duplicateChildren: boolean,
    includeParts: boolean
  ) {
    const input = <CreateVariantInput>{
      variant_name: variantName,
      original_component_id: originalComponentId,
      duplicate_children: duplicateChildren,
      duplicate_including_parts: includeParts,
    };

    return this.createVariantAction
      .mutate({
        content: input,
      })
      .pipe(handleGraphQlErrors());
  }

  public createProject$(
    projectNumber: string,
    projectName: string,
    projectDescription: string,
    initLocation: Location,
    initPath: string
  ) {
    const input = <CreateProjectInput>{
      project_number: projectNumber,
      project_name: projectName,
      project_description: projectDescription,
      init_location: initLocation,
      init_project_folder: initPath,
    };

    return this.createProjectAction
      .mutate({
        content: input,
      })
      .pipe(handleGraphQlErrors());
  }

  public createTask$(
    taskTitle: string,
    task_description: string,
    date_start: Date,
    date_end: Date,
    worker_ids: uuid[],
    projectId: uuid,
    componentIds: uuid[],
    blobRoutes: string[],
    checklistTemplateId?: uuid
  ) {
    const input = <CreateTaskInput>{
      task_title: taskTitle,
      task_description: task_description,
      date_start: date_start,
      date_end: date_end,
      worker_ids: worker_ids,
      project_id: projectId,
      component_ids: componentIds,
      blob_routes: blobRoutes,
    };

    return this.createTaskAction
      .mutate({
        task: input,
      })
      .pipe(handleGraphQlErrors());
  }

  public createPreviews$(componentIds: uuid[], previewType: PreviewType) {
    const input = <CreatePreviewsInput>{
      component_ids: componentIds,
      cgrs: PreviewType.CGR === (previewType & PreviewType.CGR),
      jpgs: PreviewType.JPG === (previewType & PreviewType.JPG),
      stls: PreviewType.STL === (previewType & PreviewType.STL),
      bounding_boxes:
        PreviewType.BoundingBox === (previewType & PreviewType.BoundingBox),
    };

    return this.createPreviewsAction
      .mutate({
        input: input,
      })
      .pipe(handleGraphQlErrors());
  }

  public freezeComponent$(componentId: uuid, version: number, freeze: boolean) {
    const input = <FreezeComponentVersionInput>{
      component_id: componentId,
      version: version,
      frozen: freeze,
    };

    return this.freezeComponentAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public startReleaseProcess$(
    id: uuid,
    componentId: uuid,
    version: number,
    checklistTemplateId: uuid,
    projectId: uuid
  ) {
    const input = <StartReleaseProcessInput>{
      id: id,
      process_name: 'Release Process',
      checklist_template_id: checklistTemplateId.toString(),
      component_id: componentId.toString(),
      component_version: version,
      project_id: projectId.toString(),
    };

    return this.startReleaseProcessAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public checkReleaseProcessStep$(
    processId: uuid,
    stepIndex: number,
    finishProcess: boolean,
    cancelProcess: boolean
  ) {
    const input = <CheckReleaseProcessStepInput>{
      release_process_id: processId.toString(),
      step_index: stepIndex,
      finish_release_process: finishProcess,
      cancel_release_process: cancelProcess,
    };

    return this.checkReleaseProcessStepAction
      .mutate({ input: input })
      .pipe(handleGraphQlErrors());
  }

  public quickReplaceComponent$(
    input: {
      component_id: string;
      upload_route: string;
    }[]
  ) {
    return this.quickReplaceComponentsAction.mutate({
      input: input.map((i) => <QuickReplaceComponentInput>i),
    });
  }

  public updateFromUpload$(variantId: uuid) {
    return this.updateFromUploadAction
      .mutate({
        variant_id: variantId.toString(),
      })
      .pipe(handleGraphQlErrors());
  }

  public exportReviewedComponents$(projectId: uuid, folderSuffix: string) {
    return this.exportReviewedComponentsAction.mutate({
      project_id: projectId.toString(),
      folder_suffix: folderSuffix,
    });
  }

  private checkOutSynchronizedWithUserLocation$(component: ComponentInstanceVersion): Observable<boolean> {
    //Disable Synced Check for debug
    if (environment.debug && !environment.production) {
      this.logger.logDebug("[checkOutSynchronizedWithUserLocation]: Skipping MD5 check in debug mode");
      return of(true);
    }

    return combineLatest([this.getFileMd5$(component), this.getLatestBlobMd5$(component)]).pipe(
      tap((hashes) => this.logger.logDebug(`[checkOutSynchronizedWithUserLocation]: MD5 hashes are [File ${hashes[0]}, Blob: ${hashes[1]}]`)),
      map((hashes) => hashes[0] === hashes[1]),
      filter((matchingHashes) => matchingHashes),
      tap(() => this.logger.logDebug("[checkOutSynchronizedWithUserLocation]: MD5 hashes are equal. Checkin is allowed.")),
      first(),
      timeout(60000),
      catchError((err) => {
        try {
          if (err.toString().startsWith('ERROR')) {
            this.logger.logFailed(err.toString());
          } else {
            this.logger.logFailed(
              '[CheckIn]: Files were not synchronized in time. Please try again. ' +
                err.message
            );
          }
        } catch (error) {
          // err as string sometimes not works
          this.logger.logFailed(error);
        }
        this.logger.logFailed(err);
        return of(false);
      })
    );
  }

  private getFileMd5$(component: ComponentInstanceVersion): Observable<string> {
    return this.getPath$(component).pipe(
      mergeMap((path) => this.fileSystemApi.getMd5Hash$(path))
    );
  }

  private getLatestBlobMd5$(component: ComponentInstanceVersion): Observable<string> {
    return this.latestBlobSubscription
      .subscribe({
        project_id: component.component.project_id,
        route: component.route,
      })
      .pipe(
        map((result) => result.data.blob_metadata_version[0]?.md5_hash ?? '')
      );
  }

  private getPath$(component: ComponentInstanceVersion): Observable<string> {
    return this.getUncRootDirectory$(component).pipe(
      map(
        (uncRoot) => uncRoot + '\\' + component.route.replace(/\//g, '\\')
      )
    );
  }

  private getUncRootDirectory$(component: ComponentInstanceVersion): Observable<string> {
    return this.projectService
      .getProjectConfiguration$(component.component.project_id)
      .pipe(
        map((config) => {
          const locationConfig = config.locations.find(
            (l) => l.location == this.userLocation
          );
          if (!locationConfig)
            throw new Error(
              '[CheckIn]: Could not find specified location in project config!'
            );
          return locationConfig.path;
        })
      );
  }
}
